import React from "react"
import { useCourseStore } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import Poem from "../../../../../components/poem"
import PaperStack from "../../../../../components/paper-stack"
import NotationSingle from "../../../../../components/notation-single"
import CourseFooterNext from "../../../../../components/course-footer-next"
import FeedbackHeaderBool from "../../../../../components/feedback-header-bool"
import { getCourseNavigation } from "../../../../../store/courses"

const Page = () => {
  const { booleanAnswerIsValid } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "versteckte-ordnung" })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext
          label="Weiter"
          to="/kurse/versteckte-ordnung/01-gedicht-erkennen/fruehjahrsputz"
        />
      }
    >
      <Seo title="Wie wenn am Feiertage" />
      <Box>
        <Stack>
          <FeedbackHeaderBool
            confirmed={booleanAnswerIsValid({
              courseId: "versteckte-ordnung",
              chapterId: "01-gedicht-erkennen",
              taskId: "feiertage",
            })}
            title="Das ist der Anfang eines Gedichts von Friedrich Hölderlin"
          />
          <Paragraph>
            Hölderlin beschreibt eine Landschaft am Morgen. Viele
            Wetter-Phänomene kommen darin vor: Blitze, Donner, Regen, Sonne.
            Anders als in einem Wetterbericht tragen sie hier jedoch dazu bei,
            eine bestimmte Atmosphäre zu erzeugen. Das erkennt man zum Beispiel
            daran, dass alle Wetter-Phänomene von einem beschreibenden Adjektiv
            begleitet werden: die "kühlenden Blize", der "ferne Donner", der
            "erfreuende Reegen", die "stille Sonne".
          </Paragraph>
          <PaperStack>
            <Poem>
              <p>Wie wenn am Feiertage, das Feld zu sehn</p>
              <p>Ein Landmann geht, des Morgens, wenn</p>
              <p>
                Aus <NotationSingle>heißer Nacht</NotationSingle> die{" "}
                <NotationSingle>kühlenden Blize</NotationSingle> fielen
              </p>
              <p>Die ganze Zeit und fern noch tönet der Donner,</p>
              <p>In sein Gestade wieder tritt der Strom,</p>
              <p>Und frisch der Boden grünt</p>
              <p>
                Und von des Himmels{" "}
                <NotationSingle> erfreuendem Reegen</NotationSingle>
              </p>
              <p>Der Weinstok trauft und glänzend</p>
              <p>
                In <NotationSingle>stiller Sonne</NotationSingle> stehn die
                Bäume des Haines
              </p>
            </Poem>
          </PaperStack>
          <Paragraph>
            Außerdem sind die Dinge der Natur alle belebt: Sie tönen, grünen,
            traufen und glänzen.
          </Paragraph>
          <PaperStack>
            <Poem>
              <p>Wie wenn am Feiertage, das Feld zu sehn</p>
              <p>Ein Landmann geht, des Morgens, wenn</p>
              <p>Aus heißer Nacht die kühlenden Blize fielen</p>
              <p>
                Die ganze Zeit und fern noch{" "}
                <NotationSingle color="spring">tönet der Donner</NotationSingle>
                ,
              </p>
              <p>
                In sein Gestade wieder{" "}
                <NotationSingle color="spring">tritt der Strom</NotationSingle>,
              </p>
              <p>
                Und{" "}
                <NotationSingle color="spring">
                  frisch der Boden grünt
                </NotationSingle>
              </p>
              <p>Und von des Himmels erfreuendem Reegen</p>
              <p>
                <NotationSingle color="spring">
                  Der Weinstok trauft
                </NotationSingle>{" "}
                und <NotationSingle color="spring">glänzend</NotationSingle>
              </p>
              <p>
                In stiller Sonne{" "}
                <NotationSingle color="spring">stehn die Bäume</NotationSingle>{" "}
                des Haines
              </p>
            </Poem>
          </PaperStack>
          <Paragraph>
            Der Satz wird am Zeilenende immer wieder unterbrochen und fügt sich
            so in die Form des Gedichts ein. Das nennt man Enjambement oder
            "Zeilensprung".
          </Paragraph>
          <PaperStack>
            <Poem>
              <p>Wie wenn am Feiertage, das Feld zu sehn</p>
              <p>
                Ein Landmann geht, des Morgens,{" "}
                <NotationSingle>wenn</NotationSingle>
              </p>
              <p>
                <NotationSingle>Aus heißer Nacht</NotationSingle> die kühlenden
                Blize <NotationSingle>fielen</NotationSingle>
              </p>
              <p>
                <NotationSingle>Die ganze Zeit</NotationSingle> und fern noch
                tönet der Donner ,
              </p>
              <p>In sein Gestade wieder tritt der Strom ,</p>
              <p>Und frisch der Boden grünt</p>
              <p>
                Und{" "}
                <NotationSingle>
                  von des Himmels erfreuendem Reegen
                </NotationSingle>
              </p>
              <p>
                <NotationSingle>Der Weinstok trauft</NotationSingle> und{" "}
                <NotationSingle>glänzend</NotationSingle>
              </p>
              <p>
                <NotationSingle>In stiller Sonne</NotationSingle> stehn die
                Bäume des Haines
              </p>
            </Poem>
          </PaperStack>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
